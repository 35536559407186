<template>
  <vs-card>
      <div>
        <div slot="header" class="flex items-center px-5 mx-2 justify-between">
          <h4>Store Alert Banners</h4>
          <vs-button @click="redirectToCreateAlert">Add New</vs-button>
        </div>
        <div class="p-5">
          <div id="data-list-list-view" class="data-list-container">
            <vs-table
              ref="table"
              :sst="true"
              :total="totalDocs"
              :data="alertNotifications"
              @change-page="handleChangePage"
              @sort="handleSort"
              :max-items="dataTableParams.limit"
              :noDataText="noDataText"
            >
          
              <template slot="thead">
                <vs-th sort-key="orgname">Title</vs-th>
                <vs-th sort-key="priorityLevel">Priority Level</vs-th>
                <vs-th sort-key="active">Status</vs-th>
                <vs-th>Actions</vs-th>
              </template>
            
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in alertNotifications">
                  <vs-td :data="data[indextr].title">
                    {{
                      data[indextr].title
                    }}
                    <div class=" mt-2">
                      <vs-chip :key="brands._id" v-for="brands in data[indextr].brandsData">
                        {{brands.brandName}}
                      </vs-chip>
                      <vs-chip :key="category._id" v-for="category in data[indextr].categoriesData">
                        {{category.name}}
                      </vs-chip>
                      <vs-chip :key="data[indextr]._id" v-if="data[indextr].homepage_display">
                        Homepage
                      </vs-chip>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].priorityLevel">
                    {{ priorityLevels[data[indextr].priorityLevel - 1] }}
                  </vs-td>
                  <vs-td :data="data[indextr].active">
                    <vs-switch @click="handleChangeStatus(data[indextr])" v-model="data[indextr].active"></vs-switch>
                  </vs-td>
                  <vs-td :data="data[indextr]._id">
                    <vs-button
                      style="float: left"
                      size="small"
                      type="border"
                      @click="redirectToAlertDetails(data[indextr]._id)"
                      class="m-1"
                    >
                      Edit</vs-button
                    >
                    <vs-button
                      style="float: left"
                      size="small"
                      type="border"
                      @click="redirectToPreviewDetails(data[indextr]._id)"
                      class="m-1"
                    >
                      Preview</vs-button
                    >
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div
              class="
                m-2
                flex
                sm:justify-between
                justify-center
                items-center
                flex-wrap
              "
              style="height: 35px; padding-bottom: 30px"
            >
              <span class="mr-2">
                {{
                  dataTableParams.page * dataTableParams.limit -
                  (dataTableParams.limit - 1)
                }}
                -
                {{
                  totalDocs - dataTableParams.page * dataTableParams.limit > 0
                    ? dataTableParams.page * dataTableParams.limit
                    : totalDocs
                }}
                of {{ totalDocs }}
              </span>
            
              <div class="pagination-div">
                <paginate
                  :page-count="totalPage"
                  :click-handler="handleChangePage"
                  class="pagination"
                  :page-range="9"
                  :prevText="'<'"
                  :nextText="'>'"
                ></paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-card>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  
  export default {
    name: "organization-table",
    components: {},
    props: {
      franchiseId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        noDataText: "Loading..",
        orgCount: 0,
        totalDocs: 0,
        currentPage: 1,
        searchInputString: null,
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "desc",
          page: 1,
          limit: 25,
          filter: "All",
        },
        alertNotifications: [],
        serverResponded: false,
        limitOptions: [5, 10, 25, 50, 100],
        filterList: [
          { value: "All", label: "All Nurses" },
          { value: "Approved", label: "Approved" },
          { value: "Unapproved", label: "Unapproved" },
        ],
        awaitingSearch: null,
        priorityLevels: [
          "High", "Medium", "Low"
        ]
      };
    },
    methods: {
      ...mapActions("storeAlertNotification", ["fetchStoreAlertNotifications", "updateStoreAlertNotificationDetails"]),
      async getStoreAlertNotifications() {
        try {
          this.$vs.loading();
          const res = await this.fetchStoreAlertNotifications(this.dataTableParams)
          this.alertNotifications = res.data.data.docs
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.currentPage = res.data.data.pagination.page;
          this.$vs.loading.close();
        } catch (error) { 
          this.$vs.loading.close();
          console.log(error)
        }
        
      },

      async handleChangeStatus(data) {
        try {
          const tempData = {
            active: !data.active
          }
          await this.updateStoreAlertNotificationDetails({id: data._id, data: tempData});
          this.$vs.notify({
            title: "Alert Notification Status",
            text: "Alert notification status update successfully.",
            color: "success",
          });
          this.refetch();
        }
        catch(err){
          console.log(err.message)
          this.$vs.notify({
            title: "Alert notification Status",
            text: "Updating of alert notification status failed.",
            color: "danger",
          });
        }
        
      },
      refetch() {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getStoreAlertNotifications();
      },
      handleSearch(searching) {
        if (this.awaitingSearch) {
          clearTimeout(this.awaitingSearch);
          this.awaitingSearch = null;
        }
        this.awaitingSearch = setTimeout(() => {
          this.serverResponded = false;
          this.dataTableParams.search = searching;
          this.dataTableParams.page = 1;
          this.$refs.table.currentx = 1;
          this.getStoreAlertNotifications();
        }, 500);
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getStoreAlertNotifications();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getStoreAlertNotifications();
      },
      redirectToCreateAlert() {
        this.$router.push({
          name: "CreateAlert",
        });
      },
      redirectToAlertDetails(id) {
        this.$router.push({
          name: "SuperAdminAlertDetails",
          params: {
            alertId: id,
          },
        });
      },
      redirectToPreviewDetails(id) {
        this.$router.push({
          name: "AlertPreview",
          params: {
            alertId: id,
          },
        });
      },
    },
    computed: {
      totalPage: function () {
        return this.totalDocs / this.dataTableParams.limit >
          parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      },
    },
    watch: {
      searchInputString: function (newVal, oldVal) {
        this.handleSearch(newVal);
      },
      "dataTableParams.page": function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getStoreAlertNotifications();
        }
      },
      "dataTableParams.limit": function (newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getStoreAlertNotifications();
        }
      },
      "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
        if (newfilterVal !== oldFilterVal) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.filter = newfilterVal;
          this.getStoreAlertNotifications();
        }
      },
    },
    async created() {
      this.getStoreAlertNotifications();
    },
  };
  </script>
  <style scoped>
  .mobile-text {
    color: #164d3d;
  }
  </style>
  